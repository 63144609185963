import { required, min, max } from "vuelidate/lib/validators";
import Tinymce from "vue-tinymce-editor";

export default {
    name: "admin-formulario-evento",
    components: {
        Tinymce
    },
    data() {
        return {
            arTiposEvento: [],
            blLoading: true,
            blDialogExcluir: false,
            blExcluindo: false,
            blErro: false,
            blSalvando: false,
            datepicker: false,
            obForm: {
                id: 0,
                nome: "",
                data: "",
                descricao: "",
                id_tipo_evento: ""
            },
            obOptions: {
                language_url:
                    process.env.VUE_APP_ROOT_API + "../langs-tinymce/pt_BR.js",
                //images_upload_url: process.env.VUE_APP_ROOT_API + "post/uploadImagem",
                convert_urls: false
            }
        };
    },
    validations: {
        obForm: {
            nome: {
                required
            },
            data: {
                required
            },
            descricao: {
                required
            },
            id_tipo_evento: {
                required
            }
        }
    },
    created() {
        this.obterTiposEvento();

        if (this.$route.params.id) {
            this.obter(this.$route.params.id);
        } else {
            this.blLoading = false;
        }
    },
    computed: {
        computedDateFormatted() {
            if (!this.obForm.data) return null;

            const [year, month, day] = this.obForm.data.split("-");
            return `${month}/${day}/${year}`;
        }
    },
    methods: {
        obterTiposEvento() {
            this.$root.$api.get("evento/listarTiposEvento").then(response => {
                this.arTiposEvento = response.retorno;
            });
        },
        editorInit() {
            if (this.obForm.id != 0)
                this.$refs.refTinymce.editor.setContent(this.obForm.descricao);
        },
        parseDate() {
            if (!this.obForm.data) return null;

            const [year, month, day] = this.obForm.data.split("-");
            return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
        },
        obter(id) {
            this.$root.$api.get("evento/obter/" + id).then(response => {
                this.$root.$utils.preencheFormularios(
                    this.obForm,
                    response.retorno
                );

                this.blLoading = false;
            });
        },
        mensagemErro(strCampo) {
            return this.$utils.handleErrors(strCampo, this);
        },
        validarFormulario() {
            this.blErro = false;

            this.$v.obForm.$touch();

            if (this.$v.obForm.$invalid) {
                this.blErro = true;
            }
        },
        submit() {
            this.validarFormulario();

            if (this.blErro) {
                return false;
            }

            this.blSalvando = true;

            this.$root.$api
                .post("evento/salvar", this.obForm)
                .then(response => {
                    this.blSalvando = false;

                    this.$router.push({
                        name: "admin-evento"
                    });
                });
        },
        excluir() {
            this.blExcluindo = true;

            this.$root.$api
                .delete("evento/excluir/" + this.obForm.id)
                .then(response => {
                    this.blDialogExcluir = false;

                    this.$router.push({
                        name: "admin-evento"
                    });
                });
        }
    }
};
